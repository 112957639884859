import s from "../News/NewsPages.module.css"
import img from "../../assets/Webs/Web36.png"
import {WebShareBlock} from "./Webs1";


const Webs36 = () => {

    return (
        <div className={s.main}>
            <h1>
                Проведен вебинар по вопросам об ограничении родителей
                в родительских правах, лишении родительских прав, отобрании ребенка
                у родителей при непосредственной угрозе его жизни и здоровью
            </h1>
            <div className={s.date}>
                12.12.2024
            </div>
            <div className={s.newsPage}>
                <div className={s.newsImg}>
                    <img src={img} alt=""/>
                </div>
                <p>
                    ФГБУ «Центр защиты прав и интересов детей» 4 декабря 2024 года проведен вебинар по вопросам об
                    ограничении родителей в родительских правах, лишении родительских прав, отобрании ребенка у
                    родителей при непосредственной угрозе его жизни и здоровью, для специалистов органов опеки и
                    попечительства.
                </p>
                <p>
                    К участию в вебинарах также приглашались представители исполнительных органов субъектов Российской
                    Федерации, осуществляющих государственное управление в сфере опеки и попечительства в отношении
                    несовершеннолетних граждан, и специалисты организаций для детей-сирот и детей, оставшихся
                    без попечения родителей.
                </p>
                <p>
                    В вебинаре приняли участие 2057 человек.
                    Запись и материалы вебинара размещены по ссылке: <a
                    href="https://frcopeca.ru/webinars/">https://frcopeca.ru/webinars/</a>
                </p>
                <p>
                    ПРИМЕЧАНИЕ:<br/>
                    Ссылка на материалы вебинара <a href="https://disk.yandex.ru/d/bDmNJDBFSLNTKg"
                                                    target="_blank">https://disk.yandex.ru/d/bDmNJDBFSLNTKg</a>
                </p>

                <WebShareBlock/>
            </div>
        </div>
    )
}
export default Webs36
