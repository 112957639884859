import s from "./Main.module.css";
import {navDD} from "../../assets/Navigator/navDD";
import {useState} from "react";
import {CaretRightOutlined} from "@ant-design/icons";

const NavigatorDD = () => {
    const [searchTerm, setSearchTerm] = useState("");

    // Фильтруем данные navDD по введённому запросу
    const filteredNavDD = navDD.filter(el =>
        el[0][0].col1.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={s.main}>
            <h1>Навигатор ООиП</h1>
            <h1 style={{ fontSize: "30px", color: "#333333" }}>
                Перечень органов исполнительной власти субъектов РФ, осуществляющих управление в сфере опеки и попечительства в отношении несовершеннолетних граждан
            </h1>

            {/* Поле поиска */}
            <div className={s.searchContainer}>
                <input
                    type="text"
                    placeholder="Поиск по названию региона"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={s.searchInput}
                />
            </div>

            <div className={s.greyBlock}>
                {filteredNavDD.map((el) => (
                    <div key={el[0][0].col1}>
                        <Region reestrSlice={el} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NavigatorDD

const Region = ({reestrSlice}) => {
    const [active, setActive] = useState(false)

    return <div style={{paddingTop: "20px"}}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <img src="/logo192.png" style={{
                paddingRight: "4px",
                width: "30px"
            }}/><strong>{reestrSlice[0][0].col1} </strong><CaretRightOutlined
            style={{
                rotate: `${active ? "0deg" : "90deg"}`,
                transition: "all 1s",
                marginLeft: "8px",
                fontSize: "20px"
            }}/>
        </div>
        {active ? <>{reestrSlice.map(((oblasti, id) => <ShowedLocation oblasti={oblasti} id={id}
                                                                       reestrSlice={reestrSlice}/>))}   </> :
            <div></div>}
    </div>
}

const ShowedLocation = ({oblasti, id, reestrSlice}) => {
    const [activeRegion, setActiveRegion] = useState(false)
    return <div>

        <div onClick={() => {
            activeRegion ? setActiveRegion(false) : setActiveRegion(true)
        }} style={{textAlign: "center"}}>
            <div style={{paddingBottom: "30px"}} className={s.region}><img src="/logo192.png" style={{
                paddingRight: "4px",
                height: "26px"
            }}/>{oblasti[0].col2}<CaretRightOutlined
                style={{
                    rotate: `${activeRegion ? "0deg" : "90deg"}`,
                    transition: "all 1s",
                    marginLeft: "8px",
                    fontSize: "20px"
                }}/></div>
            {activeRegion ? <div>
                {oblasti.map(((centers) => <Location col2={centers.col2} col3={centers.col3} col4={centers.col4}
                                                     col6={centers.col6}
                                                     col7={centers.col7}
                                                     col5={centers.col5.split(',').map(line => <a
                                                         href={`mailto:${line}`}>{line}</a>)}
                                                     />))
                }
            </div> : <></>
            }

        </div>
    </div>
}


const Location = ({col3, col4, col5, col6, col7, col8}) => {

    return <div className={s.lockationsContainer}>
        <div className={s.lockations2}>
            <div className={s.lockationsCol}>
                {col3}
            </div>
            <div className={s.lockationsCol}>
                {col4}
            </div>
            <div className={s.lockationsCol}>
                {col5}
            </div>
            <div className={s.lockationsCol}>
                {col6}
            </div>
            <div className={s.lockationsCol} >
                {col7}
            </div>
        </div>
    </div>
}