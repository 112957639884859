import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_12 = () => {
    const [respData, setData] = useState({
        регион: "",
        p3: "да",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p17: "",
        p18: "",
        p19: "",
        p20: "",
        p21: "",
        p22: "",
        p23: "",
        p24: "",
        p25: "",
        p26: "",
        p27: "",
        p28: "",
        p29: "да",
        p30: "",
        p31: "",
        p32: "",
        p33: "да",
        p34: "",
        p35: "",
        p36: "",
        p37: "",
        p38: "",
        p39: "",
        p40: "да",
        p41: "",
        p42: "",
        p43: "",
        p44: "",
        p45: "",
        p46: "",
        p47: "",
        p48: "",
        p49: "",
        p50: "",
        p51: "",
        p52: "",
        p53: "",
        p54: "",
        p55: "",
        p56: "",
        p57: "",
        p58: "",
        p59: "",
        p60: "",
        p61: "",
        p62: "",
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/fz24?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p3: "да",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: "",
                        p17: "",
                        p18: "",
                        p19: "",
                        p20: "",
                        p21: "",
                        p22: "",
                        p23: "",
                        p24: "",
                        p25: "",
                        p26: "",
                        p27: "",
                        p28: "",
                        p29: "да",
                        p30: "",
                        p31: "",
                        p32: "",
                        p33: "да",
                        p34: "",
                        p35: "",
                        p36: "",
                        p37: "",
                        p38: "",
                        p39: "",
                        p40: "да",
                        p41: "",
                        p42: "",
                        p43: "",
                        p44: "",
                        p45: "",
                        p46: "",
                        p47: "",
                        p48: "",
                        p49: "",
                        p50: "",
                        p51: "",
                        p52: "",
                        p53: "",
                        p54: "",
                        p55: "",
                        p56: "",
                        p57: "",
                        p58: "",
                        p59: "",
                        p60: "",
                        p61: "",
                        p62: "",
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Мониторинг реализации положений Федерального закона от 4 августа 2023 г. № 461-ФЗ «О внесении изменений
                в Федеральный закон «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся
                без попечения родителей» в 2024 году</h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_12;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                регион: respData.регион,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16,
                p17: respData.p17,
                p18: respData.p18,
                p19: respData.p19,
                p20: respData.p20,
                p21: respData.p21,
                p22: respData.p22,
                p23: respData.p23,
                p24: respData.p24,
                p25: respData.p25,
                p26: respData.p26,
                p27: respData.p27,
                p28: respData.p28,
                p29: respData.p29,
                p30: respData.p30,
                p31: respData.p31,
                p32: respData.p32,
                p33: respData.p33,
                p34: respData.p34,
                p35: respData.p35,
                p36: respData.p36,
                p37: respData.p37,
                p38: respData.p38,
                p39: respData.p39,
                p40: respData.p40,
                p41: respData.p41,
                p42: respData.p42,
                p43: respData.p43,
                p44: respData.p44,
                p45: respData.p45,
                p46: respData.p46,
                p47: respData.p47,
                p48: respData.p48,
                p49: respData.p49,
                p50: respData.p50,
                p51: respData.p51,
                p52: respData.p52,
                p53: respData.p53,
                p54: respData.p54,
                p55: respData.p55,
                p56: respData.p56,
                p57: respData.p57,
                p58: respData.p58,
                p59: respData.p59,
                p60: respData.p60,
                p61: respData.p61,
                p62: respData.p62,
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("регион", values.регион);
                data2.append("p3", (values.p3 === "" || values.p3 === "да") ? "да" : "нет");
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("p17", values.p17);
                data2.append("p18", values.p18);
                data2.append("p19", values.p19);
                data2.append("p20", values.p20);
                data2.append("p21", values.p21);
                data2.append("p22", values.p22);
                data2.append("p23", values.p23);
                data2.append("p24", values.p24);
                data2.append("p25", values.p25);
                data2.append("p26", values.p26);
                data2.append("p27", values.p27);
                data2.append("p28", values.p28);
                data2.append("p29", (values.p29 === "" || values.p29 === "да") ? "да" : "нет");
                data2.append("p30", values.p30);
                data2.append("p31", values.p31);
                data2.append("p32", values.p32);
                data2.append("p33", (values.p33 === "" || values.p33 === "да") ? "да" : "нет");
                data2.append("p34", values.p34);
                data2.append("p35", values.p35);
                data2.append("p36", values.p36);
                data2.append("p37", values.p37);
                data2.append("p38", values.p38);
                data2.append("p39", values.p39);
                data2.append("p40", (values.p40 === "" || values.p40 === "да") ? "да" : "нет");
                data2.append("p41", values.p41);
                data2.append("p42", values.p42);
                data2.append("p43", values.p43);
                data2.append("p44", values.p44);
                data2.append("p45", values.p45);
                data2.append("p46", values.p46);
                data2.append("p47", values.p47);
                data2.append("p48", values.p48);
                data2.append("p49", values.p49);
                data2.append("p50", values.p50);
                data2.append("p51", values.p51);
                data2.append("p52", values.p52);
                data2.append("p53", values.p53);
                data2.append("p54", values.p54);
                data2.append("p55", values.p55);
                data2.append("p56", values.p56);
                data2.append("p57", values.p57);
                data2.append("p58", values.p58);
                data2.append("p59", values.p59);
                data2.append("p60", values.p60);
                data2.append("p61", values.p61);
                data2.append("p62", values.p62);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/fz24`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>


                    <label>Федеральный закон № 159-ФЗ - Федеральный закон от 21 декабря 1996 г. № 159-ФЗ «О
                        дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без
                        попечения родителей»<br/>
                        сертификат - выплата на приобретение благоустроенного жилого помещения в
                        собственность или для полного погашения кредита (займа) по договору, обязательства заемщика
                        по
                        которому обеспечены ипотекой, предоставляемая в соответствии со статьей 8.1 Федерального
                        закона
                        № 159-ФЗ<br/>
                        региональная социальная выплата – выплата, предусмотренная законодательством
                        субъекта
                        Российской Федерации на приобретение жилого помещения (дополнительно к мерам,
                        предусмотренным
                        статей 8.1 Федерального закона № 159-ФЗ)<br/>
                        ЕПГУ – федеральная государственная информационная
                        система «Единый портал государственных и муниципальных услуг (функций)»</label>
                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />
                    <label>I. Предоставление сертификата в соответствии со статьей 8.1 Федерального закона №
                        159-ФЗ</label>

                    <label htmlFor="p3">1. Наличие сформированного реестра сертификатов на 2024 год</label>
                    <Field disabled={readOnly} as="select" id="p3" name="p3" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p4">1.1 в случае положительного ответа, укажите количество сертификатов в
                        реестре</label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()}/>

                    <label htmlFor="p5">1.2 в случае отрицательного ответа, укажите выдавались ли сертификаты в 2024
                        году
                        и каким образом это осуществлялось</label>
                    <Field readOnly={readOnly} id="p5" name="p5" as="textarea"/>

                    <label htmlFor="p6">2. Количество поступивших в 2024 году заявлений о предоставлении сертификата
                        в
                        соответствии со статьей 8.1 Федерального закона № 159-ФЗ, из них:</label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p7">2.1 количество заявлений от лиц, имеющих преимущественное право на
                        предоставление
                        сертификата (из строки 2) (сумма строк 2.1.1+2.1.2+2.1.3), из них:</label>
                    <Field readOnly={readOnly} id="p7" name="p7" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p8">2.1.1 количество заявлений о предоставлении сертификата на приобретение
                        жилого
                        помещения в общую собственность с несовершеннолетним ребенком (детьми) и (или)
                        супругом</label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p9">2.1.2 количество заявлений о предоставлении сертификата для полного погашения
                        ипотечного кредита за счет сертификата и использования средств (части средств) материнского
                        (семейного) капитала</label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p10">2.1.3 количество заявлений от лиц, принимавших участие в СВО</label>
                    <Field readOnly={readOnly} id="p10" name="p10" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p11">2.2 количество заявлений, поступивших в электронном виде (из строки
                        2)</label>
                    <Field readOnly={readOnly} id="p11" name="p11" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p12">2.3 наименование информационной системы, используемой для приема и обработки
                        заявлений о предоставлении сертификата, поступивших через ЕПГУ</label>
                    <Field readOnly={readOnly} id="p12" name="p12" as="textarea" required={true}/>

                    <label htmlFor="p13">3. Количество выданных в 2024 году сертификатов, из них:</label>
                    <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p14">3.1 количество сертификатов, выданных лицам, имеющим преимущественное право
                        на
                        предоставление сертификата</label>
                    <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p15">4. Количество реализованных в 2024 году сертификатов, из них:</label>
                    <Field readOnly={readOnly} id="p15" name="p15" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p16">4.2 на приобретение жилого помещения в собственность (из строки 4)</label>
                    <Field readOnly={readOnly} id="p16" name="p16" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p17">4.3 на погашение ипотечного кредита (из строки 4)</label>
                    <Field readOnly={readOnly} id="p17" name="p17" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p18">4.4 реализованных в другом субъекте Российской Федерации (из строки
                        4)</label>
                    <Field readOnly={readOnly} id="p18" name="p18" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p19">5. Количество отказов в предоставлении сертификата в 2024 году, из
                        них:</label>
                    <Field readOnly={readOnly} id="p19" name="p19" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p20">5.1 по причине недостижения заявителем установленного возраста</label>
                    <Field readOnly={readOnly} id="p20" name="p20" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p21">5.2 по причине отсутствия у заявителя необходимого дохода</label>
                    <Field readOnly={readOnly} id="p21" name="p21" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p22">5.3 по причине имеющейся у заявителя задолженности по налогам и
                        сборам</label>
                    <Field readOnly={readOnly} id="p22" name="p22" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p23">5.4 по причине наличия у заявителя психических заболеваний, алкогольной или
                        наркотической зависимости</label>
                    <Field readOnly={readOnly} id="p23" name="p23" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p24">5.5 по причине наличия у заявителя судимости и (или) факта уголовного
                        преследования за умышленные преступления</label>
                    <Field readOnly={readOnly} id="p24" name="p24" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p25">5.6 по причине наличия обстоятельств, свидетельствующих о необходимости
                        оказания
                        заявителю содействия в преодолении трудной жизненной ситуации</label>
                    <Field readOnly={readOnly} id="p25" name="p25" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p26">5.7 по нескольким основаниям из 5.2-5.6</label>
                    <Field readOnly={readOnly} id="p26" name="p26" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p27">5.8 Отказов вынесено по заявлениям, поступившим через ЕПГУ(из строки
                        5)</label>
                    <Field readOnly={readOnly} id="p27" name="p27" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p28">6. Количество заявлений о предоставлении сертификата, находящихся на
                        рассмотрении (решение не принято) (из строки 2)</label>
                    <Field readOnly={readOnly} id="p28" name="p28" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p29">7. Наличие сформированного реестра сертификатов на 2025 год</label>
                    <Field disabled={readOnly} as="select" id="p29" name="p29" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p30">7.1 в случае положительного ответа, укажите количество сертификатов в
                        реестре</label>
                    <Field readOnly={readOnly} id="p30" name="p30" type="number" onWheel={(e) => e.target.blur()}/>

                    <label htmlFor="p31">7.2 в случае отрицательного ответа, укажите причину</label>
                    <Field readOnly={readOnly} id="p31" name="p31" as="textarea"/>

                    <label>II. Предоставление региональной социальной выплаты</label>


                    <label htmlFor="p40">1. В субъекте Российской Федерации предусмотрена региональная социальная
                        выплата</label>
                    <Field disabled={readOnly} as="select" id="p40" name="p40" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p41">2. Количество поступивших в 2024 году заявлений о предоставлении
                        региональной
                        социальной выплаты, из них:</label>
                    <Field readOnly={readOnly} id="p41" name="p41" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p42">2.1 принято решение об отказе в выдаче региональной социальной выплаты (из
                        строки 2)</label>
                    <Field readOnly={readOnly} id="p42" name="p42" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p43">2.2 количество заявлений, находящихся на рассмотрении (решение не принято)
                        (из
                        строки 2)</label>
                    <Field readOnly={readOnly} id="p43" name="p43" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p44">3. Количество реализованных в 2024 году региональных социальных выплат, из
                        них:</label>
                    <Field readOnly={readOnly} id="p44" name="p44" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p45">3.1 реализованных в 2024 году, но выданных ранее</label>
                    <Field readOnly={readOnly} id="p45" name="p45" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label>III. Сокращение срока действия договора найма специализированного жилого
                        помещения</label>

                    <label htmlFor="p47">1. Количество заявлений о сокращении срока действия договора найма
                        специализированного жилого помещения, поступивших в 2024 году, из них:</label>
                    <Field readOnly={readOnly} id="p47" name="p47" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p48">1.1 количество заключенных договоров социального найма в связи с сокращением
                        срока действия договора найма специализированного жилого помещения</label>
                    <Field readOnly={readOnly} id="p48" name="p48" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p49">1.2 количество отказов в сокращении срока действия договора найма
                        специализированного жилого помещения, из них:</label>
                    <Field readOnly={readOnly} id="p49" name="p49" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p50">1.2.1 по причине недостижения заявителем возраста 23 лет</label>
                    <Field readOnly={readOnly} id="p50" name="p50" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p51">1.2.2 по причине отсутствия у заявителя необходимого дохода</label>
                    <Field readOnly={readOnly} id="p51" name="p51" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p52">1.2.3 по причине надлежащего исполнения обязанностей нанимателя жилого
                        помещения</label>
                    <Field readOnly={readOnly} id="p52" name="p52" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p53">1.2.4 по причине имеющейся у заявителя задолженности по налогам и
                        сборам</label>
                    <Field readOnly={readOnly} id="p53" name="p53" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p54">1.2.5 по причине наличия у заявителя психических заболеваний, алкогольной
                        или
                        наркотической зависимости</label>
                    <Field readOnly={readOnly} id="p54" name="p54" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p55">1.2.6 по причине наличия у заявителя судимости и (или) факта уголовного
                        преследования за умышленные преступления</label>
                    <Field readOnly={readOnly} id="p55" name="p55" type="number" onWheel={(e) => e.target.blur()} required={true}/>
                    <label htmlFor="p56">1.2.7 по причине наличия обстоятельств, свидетельствующих о необходимости
                        оказания заявителю содействия в преодолении трудной жизненной ситуации</label>
                    <Field readOnly={readOnly} id="p56" name="p56" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p57">1.2.8 по нескольким основаниям из 1.2.2-1.2.7</label>
                    <Field readOnly={readOnly} id="p57" name="p57" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p58">2. Количество заявлений о сокращении срока действия договора найма
                        специализированного жилого помещения, поступивших в 2024 году через ЕПГУ (из строки
                        1)</label>
                    <Field readOnly={readOnly} id="p58" name="p58" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p59">2.1 Количество отказов в сокращении срока действия, вынесенных по
                        заявлениям,
                        поступившим через ЕПГУ (из строки 2)</label>
                    <Field readOnly={readOnly} id="p59" name="p59" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p60">2.2 наименование информационной системы, используемой для приема и обработки
                        заявлений о сокращении срока действия договора найма специализированного жилого помещения,
                        поступивших через ЕПГУ</label>
                    <Field readOnly={readOnly} id="p60" name="p60" as="textarea" required={true}/>

                    <label htmlFor="p61">Комментарии (при необходимости в данной строке можно написать комментарии к
                        любым пунктам мониторинга (указав номер пункта)</label>
                    <Field readOnly={readOnly} id="p61" name="p61" as="textarea"/>

                    <label htmlFor="p62">Орган исполнительной власти субъекта Российской Федерации, а также
                        контактные
                        данные ответственного за заполнение формы мониторинга (ФИО, должность, номер телефона, адрес
                        электронной почты)</label>
                    <Field readOnly={readOnly} id="p62" name="p62" as="textarea" required={true}/>

                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};