import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_14 = () => {
    const [respData, setData] = useState({
        регион: "",
        p1: "да",
        p2: "да",
        p3: "да",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p17: ""
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/admreg24?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: "",
                        p17: ""
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Сведения по фактическому проживанию
                лиц из числа детей-сирот и детей, оставшихся без попечения родителей,
                своевременно не обеспеченных жилыми помещениями
            </h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_14;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                регион: respData.регион,
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16,
                p17: respData.p17,
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("регион", values.регион);
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("p17", values.p17);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/admreg24`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <label>Сокращенные понятия, применяемые в мониторинге:<br/>
                        лица из числа детей-сирот – лица из числа детей-сирот и детей, оставшихся без попечения
                        родителей, а также лица, которые относились к категории детей-сирот и детей, оставшихся без
                        попечения родителей, в возрасте старше 23 лет;</label>

                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />

                    <label htmlFor="p1">1. Численность лиц из числа детей-сирот, у которых право на обеспечение
                        жилыми
                        помещениями возникло, но не реализовано по состоянию на 31 декабря 2024 г. (сумма строк
                        1.1+1.2+1.3+1.4+1.5+1.6+1.7+1.8+1.9+1.10+1.11+1.12+1.13)</label>
                    <Field readOnly={readOnly} id="p1" name="p1" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p2">1.1 из них (из строки 1): органами исполнительной власти субъекта РФ/органами
                        местного самоуправления предоставлены жилые помещения во временное пользование, включая
                        жилые
                        помещения маневренного /иного жилищного фонда</label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p3">1.2 проживают в организациях социального обслуживания (дома-интернаты, дома
                        ночного пребывания, центры социальной адаптации для лиц без определенного места жительства,
                        социальные гостиницы и др.)</label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p4">1.3 проживают в организациях системы здравоохранения</label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p5">1.4 проживают в общежитиях профессиональных образовательных
                        организаций </label>
                    <Field readOnly={readOnly} id="p5" name="p5" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p6">1.5 проживают в организациях для детей-сирот и детей, оставшихся без
                        попечения
                        родителей</label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p7">1.6 проживают временно на безвозмездной основе в жилых помещениях,
                        принадлежащих
                        НКО/частным лицам</label>
                    <Field readOnly={readOnly} id="p7" name="p7" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p8">1.7 проживают в арендованных жилых помещениях</label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p9">1.7.1 из них (из строки 1.7) предоставлена компенсация расходов на оплату
                        аренды/найма жилого помещения, в котором они временно проживают, из средств бюджета субъекта
                        РФ/местного бюджета</label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p10">1.8 проживают у родственников (знакомых)</label>
                    <Field readOnly={readOnly} id="p10" name="p10" type="number" onWheel={(e) => e.target.blur()}/>

                    <label htmlFor="p11">1.9 проживают у бывших опекунов (попечителей), приемных родителей</label>
                    <Field readOnly={readOnly} id="p11" name="p11" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p12">1.10 находятся в местах лишения свободы</label>
                    <Field readOnly={readOnly} id="p12" name="p12" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p13">1.11 проходят службу в вооруженных силах Российской Федерации (включая
                        участие в
                        СВО)</label>
                    <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p14">1.12 фактическое место жительства/пребывания неизвестно</label>
                    <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p15">1.13 проживают в других местах жительства/пребывания</label>
                    <Field readOnly={readOnly} id="p15" name="p15" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p16">1.13.1 из них (из строки 1.13): при наличии других мест
                        жительства/пребывания -
                        укажите эти места и численность лиц там проживающих</label>
                    <Field readOnly={readOnly} id="p16" name="p16" as="textarea" required={true}/>

                    <label htmlFor="p17">2. Численность лиц из числа детей-сирот зарегистрированных по месту
                        жительства в
                        местных администрациях (из строки 1) число</label>
                    <Field readOnly={readOnly} id="p17" name="p17" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};