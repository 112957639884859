import s from "../News/NewsPages.module.css";
import img from "../../assets/Webs/Webs36.jpg";
import YouTube from "react-youtube";
import { WebShareBlock } from "./Webs1";

const Webs33 = () => {
  const opts = {
    width: "100%",
    minHeight: "400",
  };

  return (
    <div className={s.main}>
      <h1>
        Вебинар на тему: Вебинар по вопросам ознакомления с общими положениями
        профилактики синдрома профессионального выгорания и имеющейся практикой
        организации доказательного анализа и оценки эффективности практик
      </h1>
      <div className={s.date}>02.08.2023</div>
      <div className={s.newsPage}>
        <div className={s.newsImg}>
          <img src={img} alt="" />
        </div>
        <YouTube videoId="4kh_HfVmYoc" opts={opts} />
        <div style={{ paddingTop: "15px" }}>
          <a href="https://vk.com/video-182917553_456239034">Видеоматериал</a>
          <br />
          <a href="https://frcopeca.ru/media/media/behavior/web33_1.docx">
            Форма описания практик
          </a>
          <br />
          <a href="https://frcopeca.ru/media/media/behavior/web33_2.pdf">
            Стандарт доказательности практик в сфере детства
          </a>
          <br />
          <a href="https://frcopeca.ru/media/media/behavior/web33_3.pptx">
            Профилактика эмоционального (профессионального) выгорания
            специалистов Шпагина Е.М.
          </a>
          <br />
          <a href="https://frcopeca.ru/media/media/behavior/web33_4.pdf">
            Профессиональное здоровье специалистов Щербина А.И.
          </a>
          <br />
          <a href="https://frcopeca.ru/media/media/behavior/web33_5.pdf">
            Доказательный подход в социальной сфере: Новая парадигма
            эффективности Подушкина Т.Г.
          </a>
          <br />
        </div>
        <WebShareBlock />
      </div>
    </div>
  );
};
export default Webs33;
