import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_15 = () => {
    const [respData, setData] = useState({
        регион: "",
        p2: "",
        p3: "",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p13: "",
        p14: "",
        p15: "",
        p16: ""
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/compensation-HCS24?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: ""
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Сведения о дополнительных мерах социальной поддержки детей-сирот и детей, оставшихся без попечения
                родителей, являющихся нанимателями/членами семей нанимателей/собственниками жилых помещений,
                по освобождению/компенсации по оплате жилищно-коммунальных услуг
            </h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_15;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                регион: respData.регион,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("регион", values.регион);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", (values.p5 === "" || values.p5 === "да") ? "да" : "нет");
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/compensation-HCS24`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <label>Сокращенные понятия:<br/>
                        организации для детей-сирот – организации, в которые дети-сироты и
                        дети,
                        оставшиеся без попечения родителей, помещаются под надзор на основании акта органа опеки и
                        попечительства, и руководители которых осуществляют в отношении детей функции законного
                        представителя;<br/>
                        дети-сироты – дети-сироты и дети, оставшиеся без попечения родителей;<br/>
                        закрепленное жилое помещение – жилое помещение, в котором дети-сироты, дети, оставшиеся без
                        попечения родителей, являются нанимателями, членами семей нанимателей жилых помещений по
                        договорам социального найма, либо собственниками жилых помещений (включая долевую
                        собственность)<br/>
                        задолженность – задолженность по оплате за жилищно-коммунальные услуги в закрепленных жилых
                        помещениях (учитывать только долю ребенка) </label>


                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />


                    <label>1. Численность детей-сирот, имеющих закрепленное жилое помещение по состоянию на 1 января
                        2025 г.:</label>

                    <label htmlFor="p2">1.1 находящихся под надзором в организации для детей-сирот</label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p3">1.2 находящихся на воспитании в семьях опекунов (попечителей), приемных
                        родителей</label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p4">1.2.1 из них, проживают с опекунами (попечителями), приемными родителями в
                        закрепленных жилых помещениях</label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p5">2. Предусмотрены ли в субъекте РФ меры социальной поддержки детям, указанным
                        в
                        строках 1.1, 1.2, 1.2.1, по освобождению их от оплаты/предоставлению компенсации по оплате
                        жилищно-коммунальных услуг в закрепленных жилых помещениях</label>
                    <Field readOnly={readOnly} as="select" id="p5" name="p5" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p6">3. При положительном ответе в строке 2, укажите, в каких объемах выражается
                        освобождение/компенсация по оплате за жилищно-коммунальные услуги в закрепленных жилых
                        помещениях</label>
                    <Field readOnly={readOnly} as="select" id="p6" name="p6" required={true}>
                        <option selected="true" value="" disabled="disabled">Выберите</option>
                        <option value="100%">100%</option>
                        <option value="50%">50%</option>
                        <option value="30%">30%</option>
                        <option value="фиксированная сумма">фиксированная сумма</option>
                        <option value="иное">иное</option>
                    </Field>

                    <label htmlFor="p7">3.1 Комментарии, пояснения к строке 3 (например, если выплачивается
                        одинаковая
                        фиксированная сумма, то какое процентное соотношение к необходимым средствам для 100% оплаты
                        за
                        жилищно-коммунальные услуги данная сумма составляет; при выборе «иное» -
                        прокомментируйте)</label>
                    <Field readOnly={readOnly} id="p7" name="p7" as="textarea" required={true}/>

                    <label htmlFor="p8">4. Укажите реквизиты, наименование нормативного правового акта, которым
                        регулируется дополнительная мера по социальной поддержке по освобождению от
                        оплаты/предоставлению компенсации по оплате жилищно-коммунальных услуг/освобождению от
                        задолженности в закрепленных жилых помещениях</label>
                    <Field readOnly={readOnly} id="p8" name="p8" as="textarea" required={true}/>

                    <label htmlFor="p9">4.1 Укажите, в каком порядке освобождают от задолженности (при наличии такой
                        меры
                        поддержки)</label>
                    <Field readOnly={readOnly} id="p9" name="p9" as="textarea" required={true}/>

                    <label htmlFor="p10">5 Имеется ли задолженность в закрепленных жилых помещениях по состоянию на 1
                        января 2025 г.</label>
                    <Field readOnly={readOnly} as="select" id="p10" name="p10" required={true}>
                        <option selected="true" value="" disabled="disabled">Выберите</option>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                        <option value="не ведется сбор данной информации">не ведется сбор данной информации</option>
                    </Field>

                    <label htmlFor="p11">5.1 При положительном ответе в строке 5 поясните – включает ли имеющаяся
                        задолженность часть, образовавшуюся до признания ребенка, оставшимся без попечения
                        родителей?</label>
                    <Field readOnly={readOnly} as="select" id="p11" name="p11" required={true}>
                        <option selected="true" value="" disabled="disabled">Выберите</option>
                        <option value="да">да</option>
                        <option
                            value="да, состоит только из суммы, образовавшейся до получения ребенком статуса">да,
                            состоит только из суммы, образовавшейся до получения ребенком статуса
                        </option>
                        <option value="нет">нет</option>
                    </Field>

                    <label>6. Общая сумма накопившейся задолженности по оплате жилищно-коммунальных услуг в
                        закрепленных
                        жилых помещениях по состоянию на 1 января 2025 г. (в тысячах рублей)</label>

                    <label htmlFor="p13">6.1 находящихся под надзором в организации для детей-сирот число</label>
                    <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p14">6.2 находящихся на воспитании в семьях опекунов (попечителей), приемных
                        родителей</label>
                    <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p15">7. Комментарии </label>
                    <Field readOnly={readOnly} id="p15" name="p15" as="textarea"/>

                    <label htmlFor="p16">8. ФИО исполнителя, его контакты (номер телефона и адрес электронной
                        почты)</label>
                    <Field readOnly={readOnly} id="p16" name="p16" as="textarea" required={true}/>

                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};