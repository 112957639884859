import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_13 = () => {
    const [respData, setData] = useState({
        регион: "",
        p1: "да",
        p2: "да",
        p3: "да",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p17: "",
        p18: "",
        p19: "",
        p20: "",
        p21: "",
        p22: "",
        p23: "да",
        p24: "да",
        p25: "",
        p26: "да",
        p27: "да",
        p28: "",
        p29: "",
        p30: "",
        p31: "",
        p32: "",
        p33: "",
        p34: "",
        p35: "",
        p36: "",
        p37: "",
        p38: "",
        p39: "",
        p40: "",
        p41: "",
        p42: "",
        p43: "",
        p44: "",
        p45: "",
        p46: "",
        p47: "",
        p48: "",
        p49: "",
        p50: "",
        p52: "",
        p53: "",
        p54: "",
        p55: "",
        p56: "",
        p57: "",
        p58: "",
        p59: ""
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/housing-rights?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: "",
                        p17: "",
                        p18: "",
                        p19: "",
                        p20: "",
                        p21: "",
                        p22: "",
                        p23: "да",
                        p24: "да",
                        p25: "",
                        p26: "да",
                        p27: "да",
                        p28: "",
                        p29: "",
                        p30: "",
                        p31: "",
                        p32: "",
                        p33: "",
                        p34: "",
                        p35: "",
                        p36: "",
                        p37: "",
                        p38: "",
                        p39: "",
                        p40: "",
                        p41: "",
                        p42: "",
                        p43: "",
                        p44: "",
                        p45: "",
                        p46: "",
                        p47: "",
                        p48: "",
                        p49: "",
                        p50: "",
                        p52: "",
                        p53: "",
                        p54: "",
                        p55: "",
                        p56: "",
                        p57: "",
                        p58: "",
                        p59: "",
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Мониторинг по вопросам реализации жилищных прав детей-сирот,
                детей, оставшихся без попечения родителей, и лиц из их числа
            </h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_13;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                регион: respData.регион,
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16,
                p17: respData.p17,
                p18: respData.p18,
                p19: respData.p19,
                p20: respData.p20,
                p21: respData.p21,
                p22: respData.p22,
                p23: respData.p23,
                p24: respData.p24,
                p25: respData.p25,
                p26: respData.p26,
                p27: respData.p27,
                p28: respData.p28,
                p29: respData.p29,
                p30: respData.p30,
                p31: respData.p31,
                p32: respData.p32,
                p33: respData.p33,
                p34: respData.p34,
                p35: respData.p35,
                p36: respData.p36,
                p37: respData.p37,
                p38: respData.p38,
                p39: respData.p39,
                p40: respData.p40,
                p41: respData.p41,
                p42: respData.p42,
                p43: respData.p43,
                p44: respData.p44,
                p45: respData.p45,
                p46: respData.p46,
                p47: respData.p47,
                p48: respData.p48,
                p49: respData.p49,
                p50: respData.p50,
                p52: respData.p52,
                p53: respData.p53,
                p54: respData.p54,
                p55: respData.p55,
                p56: respData.p56,
                p57: respData.p57,
                p58: respData.p58,
                p59: respData.p59
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("регион", values.регион);
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("p17", values.p17);
                data2.append("p18", values.p18);
                data2.append("p19", values.p19);
                data2.append("p20", values.p20);
                data2.append("p21", values.p21);
                data2.append("p22", values.p22);
                data2.append("p23", (values.p23 === "" || values.p23 === "да") ? "да" : "нет");
                data2.append("p24", (values.p24 === "" || values.p24 === "да") ? "да" : "нет");
                data2.append("p25", values.p25);
                data2.append("p26", (values.p26 === "" || values.p26 === "да") ? "да" : "нет");
                data2.append("p27", (values.p27 === "" || values.p27 === "да") ? "да" : "нет");
                data2.append("p28", values.p28);
                data2.append("p29", values.p29);
                data2.append("p30", values.p30);
                data2.append("p31", values.p31);
                data2.append("p32", values.p32);
                data2.append("p33", values.p33);
                data2.append("p34", values.p34);
                data2.append("p35", values.p35);
                data2.append("p36", values.p36);
                data2.append("p37", values.p37);
                data2.append("p38", values.p38);
                data2.append("p39", values.p39);
                data2.append("p40", values.p40);
                data2.append("p41", values.p41);
                data2.append("p42", values.p42);
                data2.append("p43", values.p43);
                data2.append("p44", values.p44);
                data2.append("p45", values.p45);
                data2.append("p46", values.p46);
                data2.append("p47", values.p47);
                data2.append("p48", values.p48);
                data2.append("p49", values.p49);
                data2.append("p50", values.p50);
                data2.append("p52", values.p52);
                data2.append("p53", values.p53);
                data2.append("p54", values.p54);
                data2.append("p55", values.p55);
                data2.append("p56", values.p56);
                data2.append("p57", values.p57);
                data2.append("p58", values.p58);
                data2.append("p59", values.p59);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/housing-rights`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>

                    <label>Сокращенные понятия, применяемые в мониторинге:<br/>
                        дети-сироты и лица из их числа – дети-сироты, дети, оставшиеся без попечения родителей,
                        лица из числа детей-сирот и детей, оставшихся без попечения родителей, лица, которые относились
                        к категории детей-сирот и детей, оставшихся без попечения родителей, в возрасте старше 23
                        лет;<br/>
                        организации для детей-сирот – организации для
                        детей-сирот и детей, оставшихся без попечения родителей;<br/>
                        список – список детей-сирот и детей, оставшихся без попечения родителей, лиц из числа
                        детей-сирот и детей, оставшихся без попечения родителей, лиц, которые относились к категории
                        детей-сирот и детей, оставшихся без попечения родителей, лиц из числа детей- сирот и детей,
                        оставшихся без попечения родителей, и достигли возраста 23 лет, которые подлежат обеспечению
                        жилыми помещениями;<br/>
                        жилые помещения детей-сирот – жилые помещения, нанимателями или членами семей нанимателей по
                        договору социального найма либо собственниками которых являются дети- сироты;<br/>
                        Федеральный закон № 159-ФЗ - Федеральный закон от 21 декабря 1996 г. № 159-ФЗ «О дополнительных
                        гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей»;<br/>
                        сертификат - выплата на приобретение благоустроенного жилого помещения в собственность или для
                        полного погашения кредита (займа)
                        по договору, обязательства заемщика по которому обеспечены ипотекой, предоставляемая в
                        соответствии
                        со статьей 8.1 Федерального закона № 159-ФЗ; региональная социальная выплата – выплата,
                        предусмотренная законодательством субъекта Российской Федерации на приобретение жилого
                        помещения
                        (дополнительно к мерам, предусмотренным статей 8.1 Федерального закона № 159-ФЗ);</label>

                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />

                    <label htmlFor="p1">1. Численность детей-сирот и лиц из их числа, включенных в список в течение
                        2024
                        г. (сумма строк 1.1+1.2+1.3)</label>
                    <Field readOnly={readOnly} id="p1" name="p1" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p2">1.1 из них (из строки 1): в возрасте от 14 до 18 лет</label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p3">1.1.1 из них (из строки 1.1): выявленных и учтенных в качестве детей-сирот и
                        детей, оставшихся без попечения родителей, в 2024 г.</label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p4">1.1.2 из них (из строки 1.1.1): детей-сирот </label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p5">1.2 в возрасте от 18 до 23 лет</label>
                    <Field readOnly={readOnly} id="p5" name="p5" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p6">1.3 в возрасте от 23 лет и старше</label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p7">1.3.1 из них (из строки 1.3): имеется вступившие в законную силу решение
                        суда </label>
                    <Field readOnly={readOnly} id="p7" name="p7" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p8">1.4 Численность детей-сирот и лиц из их числа, прибывших с территории других
                        субъектов Российской Федерации (из строки 1)</label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p9">1.4.1 из них (из строки 1.4): в возрасте старше 18 лет</label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p10">1.4.2 из них (из строки 1.4.1): ранее были включены в список в другом
                        субъекте
                        РФ</label>
                    <Field readOnly={readOnly} id="p10" name="p10" as="textarea" required={true}/>

                    <label htmlFor="p11">2. Численность детей-сирот, достигших возраста 14 лет, которые были включены
                        в
                        список в 2024 г. органами опеки и попечительства в связи с неподачей законными
                        представителями
                        заявления о включении в список </label>
                    <Field readOnly={readOnly} id="p11" name="p11" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p12">3. Численность лиц из числа детей-сирот, обеспеченных жилыми помещениями в
                        2024
                        г. (сумма строк 3.1 + 3.2 + 3.3 + 3.4), из них:</label>
                    <Field readOnly={readOnly} id="p12" name="p12" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p13">3.1 численность лиц числа детей-сирот, обеспеченных жилыми помещениями по
                        договору найма специализированного жилого помещения</label>
                    <Field readOnly={readOnly} id="p13" name="p13" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p14">3.2 численность лиц из числа детей-сирот, обеспеченных жилыми помещениями по
                        договору социального найма (за исключением лиц, с которыми заключен договор социального
                        найма по
                        истечению договора найма специализированного жилого помещения) </label>
                    <Field readOnly={readOnly} id="p14" name="p14" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p15">3.3 численность лиц из числа детей-сирот, реализовавших в 2024 г.
                        региональную
                        социальную выплату </label>
                    <Field readOnly={readOnly} id="p15" name="p15" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p16">3.4 численность лиц из числа детей-сирот, реализовавших в 2024 г. сертификат
                        в
                        соответствии со статьей 8.1 Федерального закона № 159-ФЗ </label>
                    <Field readOnly={readOnly} id="p16" name="p16" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p17">4. Численность лиц из числа детей-сирот, обеспеченных жилыми помещениями в
                        2024
                        г. на основании решения суда (из строки 3)</label>
                    <Field readOnly={readOnly} id="p17" name="p17" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p18">5. Численность детей-сирот и лиц из их числа, у которых наступило право на
                        обеспечение жилыми помещениями и не реализовано по состоянию на 31 декабря 2024 г., из
                        них:</label>
                    <Field readOnly={readOnly} id="p18" name="p18" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p19">5.1 численность детей-сирот и лиц из их числа, в отношении которых вступили
                        в
                        силу и не исполнены судебные решения об обеспечении жилыми помещениями</label>
                    <Field readOnly={readOnly} id="p19" name="p19" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p20">5.2 численность лиц из числа детей-сирот, принимавших участие в специальной
                        военной операции и имеющих приоритетное право на обеспечение жилыми помещениями </label>
                    <Field readOnly={readOnly} id="p20" name="p20" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p21">5.3 численность детей-сирот и лиц из их числа, которые находились на
                        постинтернатном сопровождении в 2024 году </label>
                    <Field readOnly={readOnly} id="p21" name="p21" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p22">6. Количество заключенных договоров социального найма в связи с сокращением
                        срока действия договора найма специализированного жилого помещения в 2024 году (согласно
                        пункту
                        6.1 статьи 8 Федерального закона №159-ФЗ)</label>
                    <Field readOnly={readOnly} id="p22" name="p22" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p23">7. Наличие в субъекте РФ плана мероприятий по контролю за состоянием и
                        использованием жилых помещений, предоставленных детям-сиротам и лицам из их числа, по
                        договорам
                        найма специализированных жилых помещений </label>
                    <Field readOnly={readOnly} as="select" id="p23" name="p23" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p24">8. Наличие в субъекте РФ плана мероприятий по контролю за использованием,
                        распоряжением и сохранностью жилых помещений, нанимателями или членами семей нанимателей по
                        договору социального найма либо собственниками которых являются дети-сироты и лица из их
                        числа </label>
                    <Field readOnly={readOnly} as="select" id="p24" name="p24" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p25">8.1 Укажите реквизиты и наименование принятого в субъекте РФ нормативного
                        правового акта, устанавливающего порядок осуществления контроля за использованием и
                        сохранностью
                        жилых помещений, нанимателями или членами семей нанимателей по договору социального найма
                        либо
                        собственниками которых являются дети-сироты и лица из их числа, а также за обеспечением
                        надлежащего санитарного и технического состояния данных жилых помещений (согласно пункту 2
                        статьи 8 Федерального закона № 159-ФЗ)</label>
                    <Field readOnly={readOnly} id="p25" name="p25" as="textarea" required={true}/>

                    <label htmlFor="p26">9. Наличие в субъекте РФ реестра жилых помещений детей-сирот и лиц из их
                        числа,
                        в отношении которых осуществляется контроль за использованием, распоряжением и сохранностью,
                        а
                        также за обеспечением надлежащего санитарного и технического состояния данных жилых
                        помещений </label>
                    <Field readOnly={readOnly} as="select" id="p26" name="p26" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                    </Field>

                    <label htmlFor="p27">10. Осуществляется ли контроль за использованием, распоряжением и
                        сохранностью
                        жилых помещений, нанимателями или членами семей нанимателей по договору социального найма
                        либо
                        собственниками которых являются дети-сироты и лица из их числа</label>
                    <Field readOnly={readOnly} as="select" id="p27" name="p27" required={true}>
                        <option value="да">да</option>
                        <option value="нет">нет</option>
                        <option
                            value="только за жилыми помещениями, находящимися в собственности детей-сирот и лиц из их числа">только
                            за жилыми помещениями, находящимися в собственности детей-сирот и лиц из их числа
                        </option>
                    </Field>

                    <label htmlFor="p28">11. Количество жилых помещений детей-сирот, в отношении которых
                        осуществляется
                        контроль за сохранностью</label>
                    <Field readOnly={readOnly} id="p28" name="p28" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p29">12. Численность детей-сирот, имеющие жилые помещения, нанимателями или
                        членами
                        семей нанимателей по договору социального найма либо собственниками которых они являются, по
                        состоянию на 31 декабря 2024 г. (строка 12 больше либо равна сумме строк 12.1 +
                        12.2) </label>
                    <Field readOnly={readOnly} id="p29" name="p29" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p30">12.1 из них: численность детей-сирот, являющихся собственниками жилых
                        помещений
                        (из строки 12) </label>
                    <Field readOnly={readOnly} id="p30" name="p30" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p31">12.1.1 из них: численность детей-сирот, имеющих жилые помещения в общей либо
                        долевой собственности (из строки 12.1.)</label>
                    <Field readOnly={readOnly} id="p31" name="p31" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p32">12.1.2 из них: численность детей-сирот, имеющих жилые помещения в общей либо
                        долевой собственности с лицами, лишенными родительских прав вотношении этих детей-сирот (из
                        строки 12.1.1.)</label>
                    <Field readOnly={readOnly} id="p32" name="p32" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p33">12.2 численность детей-сирот, являющихся нанимателями жилых помещений или
                        членами семей нанимателей по договорам социального найма (из строки 12), </label>
                    <Field readOnly={readOnly} id="p33" name="p33" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p34">12.2.1 из них: численность детей-сирот, являющихся членами семей нанимателей
                        жилых помещений по договорам социального найма совместно слицами, лишенными родительских
                        прав в
                        отношении этих детей-сирот (из строки 12.2.)</label>
                    <Field readOnly={readOnly} id="p34" name="p34" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p35">12.3 проживающих с опекунами (попечителями) в данных жилых помещениях (из
                        строки
                        12) </label>
                    <Field readOnly={readOnly} id="p35" name="p35" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p36">13. Количество заявлений/обращений законных представителей детей-сирот о
                        признании невозможным проживания детей-сирот в ранее занимаемых ими жилых помещениях,
                        нанимателями или членами семей нанимателей по договорам социального найма либо
                        собственниками
                        которых они являются, в 2024 году</label>
                    <Field readOnly={readOnly} id="p36" name="p36" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p37">14. Количество заявлений/обращений лиц из числа детей-сирот о признании
                        невозможным проживания в ранее занимаемых ими жилых помещениях, нанимателями или членами
                        семей
                        нанимателей по договорам социального найма либо собственниками которых они являются, в 2024
                        г. </label>
                    <Field readOnly={readOnly} id="p37" name="p37" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p38">15. Численность детей-сирот и лиц из числа детей-сирот, включенных в список
                        в
                        2024 г. на основании признания невозможным проживания в ранее занимаемых жилых помещениях
                        (сумма
                        строк 15.1+15.2+15.3+15.4+15.5)</label>
                    <Field readOnly={readOnly} id="p38" name="p38" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p39">15.1 из них: в связи с проживанием на любом законном основании лиц, лишенных
                        родительских прав в отношении этих детей-сирот (из строки 15)</label>
                    <Field readOnly={readOnly} id="p39" name="p39" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p40">15.2 в связи с проживанием лиц, страдающих тяжелой формой хронических
                        заболеваний (из строки 15)</label>
                    <Field readOnly={readOnly} id="p40" name="p40" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p41">15.3 в связи с признанием жилого помещения непригодным для проживания по
                        основаниям и в порядке, которые установлены жилищным законодательством (из строки
                        15)</label>
                    <Field readOnly={readOnly} id="p41" name="p41" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p42">15.3.1 из них (из строки 15.3): одновременно включены в адресную программу
                        по
                        переселению граждан из аварийного жилищного фонда</label>
                    <Field readOnly={readOnly} id="p42" name="p42" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p43">15.4 в связи с тем, что общая площадь жилого помещения, приходящаяся на одно
                        лицо, проживающее в данном жилом помещении, менее учетной нормы площади жилого помещения (из
                        строки 15)</label>
                    <Field readOnly={readOnly} id="p43" name="p43" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p44">15.5 в связи с наличием обстоятельств, установленных законодательством
                        субъекта
                        Российской Федерации (из строки 15)</label>
                    <Field readOnly={readOnly} id="p44" name="p44" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p45">15.5(1) при наличии показателя в строке 15.5, укажите обстоятельства</label>
                    <Field readOnly={readOnly} id="p45" name="p45" as="textarea"/>

                    <label htmlFor="p46">16. Количество жилых помещений, нанимателями или членами семей нанимателей
                        по
                        договорам социального найма либо собственниками которых являются дети-сироты и лица из их
                        числа,
                        нуждающихся в проведении капитального или текущего ремонта, по состоянию на 31 декабря 2024
                        г.</label>
                    <Field readOnly={readOnly} id="p46" name="p46" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p47">17. Количество жилых помещений, нанимателями или членами семей нанимателей
                        по
                        договорам социального найма являются дети-сироты и лица из их числа, отремонтированных за
                        счет
                        средств бюджета субъекта РФ/местного бюджета либо на ремонт которых в 2024 г. оказана
                        материальная помощь </label>
                    <Field readOnly={readOnly} id="p47" name="p47" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p48">18. Количество жилых помещений, собственниками которых являются дети-сироты
                        и
                        лица из их числа, отремонтированных за счет средств бюджета субъекта РФ/местного бюджета
                        либо на
                        ремонт которых в 2024 г. оказана материальная помощь</label>
                    <Field readOnly={readOnly} id="p48" name="p48" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p49">19. Количество случаев предотвращения незаконных действий по вселению
                        граждан в
                        жилые помещения детей-сирот в 2024 году</label>
                    <Field readOnly={readOnly} id="p49" name="p49" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p50">20. Количество случаев принудительного обмена жилого помещения, занимаемого
                        детьми-сиротами по договорам социального найма, в 2024 году</label>
                    <Field readOnly={readOnly} id="p50" name="p50" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p52">21. Количество случаев предотвращения незаконных сделок по обмену,
                        отчуждению
                        жилых помещений детей-сирот в 2024 году</label>
                    <Field readOnly={readOnly} id="p52" name="p52" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p53">22. Количество исков в суд об истребовании недвижимого имущества детей-сирот
                        из
                        чужого незаконного владения в 2024 году</label>
                    <Field readOnly={readOnly} id="p53" name="p53" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p54">22.1 Количество судебных решений об удовлетворении исков об истребовании
                        недвижимого имущества детей-сирот из чужого незаконного владения в судебном порядке (из
                        строки
                        22) </label>
                    <Field readOnly={readOnly} id="p54" name="p54" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p55">23. Количество жилых помещений, нанимателями или собственниками которых
                        являются
                        дети-сироты, сданы в наем, по состоянию на 31 декабря 2024 года</label>
                    <Field readOnly={readOnly} id="p55" name="p55" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p56">24. Количество заключенных договоров о доверительном управлении недвижимым
                        имуществом детей-сирот по состоянию на 31 декабря 2024 года</label>
                    <Field readOnly={readOnly} id="p56" name="p56" type="number" onWheel={(e) => e.target.blur()} required={true}/>

                    <label htmlFor="p57">25. Укажите причины роста численности детей-сирот, включенных в список, у
                        которых возникло право на предоставление жилого помещения, но не реализовавших это
                        право</label>
                    <Field readOnly={readOnly} id="p57" name="p57" as="textarea" required={true}/>

                    <label htmlFor="p58">26. Комментарии (при необходимости в данной строке можно написать
                        комментарии к
                        любым пунктам мониторинга (указав номер пункта) </label>
                    <Field readOnly={readOnly} id="p58" name="p58" as="textarea" required={true}/>

                    <label htmlFor="p59">27. Орган исполнительной власти субъекта Российской Федерации, а также
                        контактные данные ответственного за заполнение формы мониторинга (ФИО, должность, номер
                        телефона, адрес электронной почты)</label>
                    <Field readOnly={readOnly} id="p59" name="p59" as="textarea" required={true}/>
                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};