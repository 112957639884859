import {LOGIN_FAILURE, SET_USER_DATA, SET_USER_DATA_ERROR} from "../types";
import axios from "axios";

export const setUserData = (config) => {
    return async (dispatch) => {
        try {
            const response = await axios.request(config);
            dispatch({
                type: SET_USER_DATA,
                payload: {
                    username: response.data.username,
                    id: response.data.id,
                },
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Токен невалиден, сбрасываем авторизацию
                localStorage.removeItem("access");
                dispatch({ type: LOGIN_FAILURE });
            } else {
                dispatch({ type: SET_USER_DATA_ERROR });
            }
        }
    };
};
