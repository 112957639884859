import s from "./Cabinet.module.css";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Report2024_11 = () => {
    const [respData, setData] = useState({
        регион: "",
        p1: "",
        p2: "",
        p3: "",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p12: "",
        p13: "",
        p14: "",
        контакт_данные: "",
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/find-child24?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p12: "",
                        p13: "",
                        p14: "",
                        контакт_данные: "",
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Форма предоставления сведений о выявлении детей-сирот и детей, оставшихся без попечения родителей, в 2024 году</h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br/>
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData}/>
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_11;

const ReportForm = ({respData}) => {
    return (
        <div>
            <Report2 respData={respData}/>
        </div>
    );
};

const Report2 = ({respData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");


    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p12: respData.p12,
                p13: respData.p13,
                контакт_данные: respData.контакт_данные,
                регион: respData.регион
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p12", values.p12);
                data2.append("p13", values.p13);
                data2.append("контакт_данные", values.контакт_данные);
                data2.append("регион", values.регион);
                data2.append("статус", "На утверждении")
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/find-child24`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />
                    <label htmlFor="p1">1. Всего детей выявлено и учтено на конец отчетного периода (сумма строк 2 -
                        13
                        )</label>
                    <Field readOnly={readOnly} id="p1" name="p1" type="number" required={true}/>
                    <label htmlFor="p2">2. из них (из строки 1): детей-сирот </label>
                    <Field readOnly={readOnly} id="p2" name="p2" type="number" required={true}/>
                    <label htmlFor="p3">3. численность детей, у которых лишены родительских прав оба родителя или
                        единственный родитель</label>
                    <Field readOnly={readOnly} id="p3" name="p3" type="number" required={true}/>
                    <label htmlFor="p4">4. численность детей, у которых ограничены в родительских правах оба родителя
                        или
                        единственный родитель</label>
                    <Field readOnly={readOnly} id="p4" name="p4" type="number" required={true}/>
                    <label htmlFor="p5">5. численность детей, у которых оба родителя или единственный родитель
                        признаны
                        недееспособными (ограниченно дееспособными)</label>
                    <Field readOnly={readOnly} id="p5" name="p5" type="number" required={true}/>
                    <label htmlFor="p6">6. численность детей, родители которых находятся под стражей или отбывают
                        наказания в виде лишения свободы</label>
                    <Field readOnly={readOnly} id="p6" name="p6" type="number" required={true}/>
                    <label htmlFor="p7">7. численность детей, у которых оба родителя или единственный родитель
                        объявлены
                        в розыск</label>
                    <Field readOnly={readOnly} id="p7" name="p7" type="number" required={true}/>
                    <label htmlFor="p8">8. численность детей, оставленных родителями в организациях по окончанию
                        срока
                        пребывания</label>
                    <Field readOnly={readOnly} id="p8" name="p8" type="number" required={true}/>
                    <label htmlFor="p9">9. численность детей, отобранных у родителей (единственного родителя)</label>
                    <Field readOnly={readOnly} id="p9" name="p9" type="number" required={true}/>
                    <label htmlFor="p10">10. численность детей, оставленных матерями (родителями) при
                        рождении</label>
                    <Field readOnly={readOnly} id="p10" name="p10" type="number" required={true}/>
                    <label htmlFor="p11">11. численность детей, у которых оба родителя или единственный родитель
                        неизвестны</label>
                    <Field readOnly={readOnly} id="p11" name="p11" type="number" required={true}/>
                    <label htmlFor="p12">12. численность детей, в отношении которых факт отсутствия родительского
                        попечения установлен решением суда</label>
                    <Field readOnly={readOnly} id="p12" name="p12" type="number" required={true}/>
                    <label htmlFor="p13">13. иное</label>
                    <Field readOnly={readOnly} id="p13" name="p13" as="textarea" required={true}/>
                    <label htmlFor="контакт_данные">Контактные данные ответственного за заполнение формы мониторинга
                        (наименование
                        ОИВ, Ф.И.О., должность, телефон (с указанием кода региона), адрес электронной почты)</label>
                    <Field readOnly={readOnly} id="контакт_данные" name="контакт_данные" as="textarea" required={true}/>
                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{color: "red"}}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};