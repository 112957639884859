import s from "./Cabinet.module.css";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Report2024_15 = () => {
    const [respData, setData] = useState({
        регион: "",
        p1: "",
        p2: "",
        p3: "",
        p4: "",
        p5: "",
        p6: "",
        p7: "",
        p8: "",
        p9: "",
        p10: "",
        p11: "",
        p13: "",
        p14: "",
        p15: "",
        p16: "",
        p17: "",
        report: ""
    });
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user.id) {
                let config = {
                    method: "GET",
                    maxBodyLength: Infinity,
                    url: `https://frcopeca.ru/api/listform25?id=${user.id}`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                };
                try {
                    const response = await axios.request(config);
                    if (response.data[0]) {
                        setData(response.data[0]);
                    }
                } catch (error) {
                    setData({
                        регион: "",
                        p1: "",
                        p2: "",
                        p3: "",
                        p4: "",
                        p5: "",
                        p6: "",
                        p7: "",
                        p8: "",
                        p9: "",
                        p10: "",
                        p11: "",
                        p13: "",
                        p14: "",
                        p15: "",
                        p16: "",
                        p17: "",
                        report: ""
                    });
                }
                setLoading(false);
            }
        };
        fetchData();
    }, [user.id]);

    if (loading) {
        return <div className={s.main}>Загрузка...</div>;
    }

    return (
        <div className={s.main}>
            <h1>Сведения о дополнительных мерах социальной поддержки детей-сирот и детей, оставшихся без попечения
                родителей, являющихся нанимателями/членами семей нанимателей/собственниками жилых помещений,
                по освобождению/компенсации по оплате жилищно-коммунальных услуг
            </h1>
            {respData.статус && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "28px",
                        fontWeight: "bold",
                        color: "#000000",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Статус: {respData.статус}
                </div>
            )}
            {respData.комментарии && (
                <div
                    style={{
                        paddingBottom: "15px",
                        fontSize: "24px",
                        fontWeight: "normal",
                        color: "red",
                        width: "90%",
                        margin: "0 auto",
                    }}
                >
                    Комментарий от проверяющего:
                    <br />
                    {respData.комментарии}
                </div>
            )}
            <ReportForm respData={respData} />
            {/* <AddForm/> */}
        </div>
    );
};
export default Report2024_15;

const ReportForm = ({ respData }) => {
    return (
        <div>
            <Report2 respData={respData} />
        </div>
    );
};

const Report2 = ({ respData }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [err, setErr] = useState("");
    const [file, setFile] = useState(null);

    const readOnly = respData.статус === "Утверждено";
    const readOnlyRegion = respData.статус !== "" || respData.статус !== null
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                регион: respData.регион,
                p1: respData.p1,
                p2: respData.p2,
                p3: respData.p3,
                p4: respData.p4,
                p5: respData.p5,
                p6: respData.p6,
                p7: respData.p7,
                p8: respData.p8,
                p9: respData.p9,
                p10: respData.p10,
                p11: respData.p11,
                p13: respData.p13,
                p14: respData.p14,
                p15: respData.p15,
                p16: respData.p16,
                p17: respData.p17,
                report: respData.report
            }}
            onSubmit={(values) => {
                let data2 = new FormData();
                data2.append("регион", values.регион);
                data2.append("p1", values.p1);
                data2.append("p2", values.p2);
                data2.append("p3", values.p3);
                data2.append("p4", values.p4);
                data2.append("p5", values.p5);
                data2.append("p6", values.p6);
                data2.append("p7", values.p7);
                data2.append("p8", values.p8);
                data2.append("p9", values.p9);
                data2.append("p10", values.p10);
                data2.append("p11", values.p11);
                data2.append("p13", values.p13);
                data2.append("p14", values.p14);
                data2.append("p15", values.p15);
                data2.append("p16", values.p16);
                data2.append("p17", values.p17);
                if (file) {
                    data2.append("report", file);
                }
                data2.append("статус", "На утверждении");
                data2.append("author", user.id);
                let config = {
                    method: respData.регион ? "patch" : "POST",
                    maxBodyLength: Infinity,

                    url: `https://frcopeca.ru/api/listform25`,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("access")}`,
                    },
                    data: data2,
                };

                axios.request(config)
                    .then(() => {
                        setErr("");
                        navigate("/profile");
                    })
                    .catch((e) => {
                        const errorMessage = e.response
                            ? e.response.data?.message || e.response.statusText || "Ошибка на сервере, попробуйте проверить данные или обратитесь в поддержку"
                            : e.message || JSON.stringify(e);

                        setErr(errorMessage);


                        console.error("Ошибка:", e);
                    });
            }}
        >
            {(errors, touched) => (
                <Form className={s.reportForm}>
                    <div className={s.instruction}>
                        <h3>Инструкция к заполнению формы</h3>
                        <p>
                            Если в субъекте РФ по каждому из пунктов формы утвержден один какой-либо уполномоченный орган, 
                            то заполните форму, указав уполномоченный орган и его контактную информацию по каждому из пунктов.
                        </p>
                        <p>
                            Если по каждому из пунктов формы необходимо указать несколько уполномоченных органов 
                            (например, полномочия переданы органам местного самоуправления), то скачайте прикрепленное 
                            к данному мониторингу приложение в формате excel, заполните и прикрепите в личном кабинете.
                        </p>
                        <p>
                            В таком случае в форме, размещенной в личном кабинете, заполните пункт 9.
                        </p>
                    </div>

                    <label>Сокращения:<br />
                        дети-сироты и лица из их числа – дети-сироты, дети, оставшиеся без попечения
                        родителей, лица из числа детей-сирот и детей, оставшихся без попечения родителей, лица, которые
                        относились к категории детей-сирот и детей, оставшихся без попечения родителей, в возрасте
                        старше 23 лет;<br />
                        Федеральный закон № 159-ФЗ - Федеральный закон от 21 декабря 1996 г. № 159-ФЗ «О
                        дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения
                        родителей»;<br />
                        уполномоченный орган – орган исполнительной власти субъекта Российской Федерации,
                        орган местного самоуправления.<br />
                        Примечание: В контактной информации укажите почтовый адрес, адрес
                        электронной почты и номер телефона для обращений (контактная информация либо приемной, либо
                        справочной, либо общего отдела, прочее), телефон «горячей линии» (при наличии). Если полномочия
                        по указанным вопросам переданы органам местного самоуправления, то необходимо указать контактную
                        информацию каждого такого органа местного самоуправления.</label>

                    <label htmlFor="регион">Субъект Российской Федерации</label>
                    <Field
                        readOnly={readOnlyRegion}
                        id="регион"
                        name="регион"
                        as="textarea"
                        required={true}
                    />

                    <label htmlFor="p1">1. Наименование уполномоченного органа на принятие решений о включении в
                        список
                        детей-сирот и лиц из их числа, подлежащих обеспечению жилыми помещениями</label>
                    <Field readOnly={readOnly} id="p1" name="p1" as="textarea" />

                    <label htmlFor="p2">1.1 контактная информация уполномоченного органа</label>
                    <Field readOnly={readOnly} id="p2" name="p2" as="textarea" />

                    <label htmlFor="p3">2. Наименование уполномоченного органа на обеспечение жилыми помещениями
                        детей-сирот и лиц из их числа</label>
                    <Field readOnly={readOnly} id="p3" name="p3" as="textarea" />

                    <label htmlFor="p4">2.1 контактная информация уполномоченного органа</label>
                    <Field readOnly={readOnly} id="p4" name="p4" as="textarea" />

                    <label htmlFor="p5">3. Наименование уполномоченного органа на принятие решения о предоставлении
                        выплаты на приобретение благоустроенного жилого помещения в собственность или для полного
                        погашения кредита (займа) по договору, обязательства заемщика по которому обеспечены
                        ипотекой, в
                        соответствии со статьей 8.1. Федерального закона № 159-ФЗ</label>
                    <Field readOnly={readOnly} id="p5" name="p5" as="textarea" />

                    <label htmlFor="p6">3.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p6" name="p6" as="textarea" />

                    <label htmlFor="p7">4. Наименование уполномоченного органа на принятие решения о соответствии
                        приобретаемого получателем сертификата жилого помещения установленным санитарным и
                        техническим
                        правилам и нормам, иным требованиям законодательства Российской Федерации</label>
                    <Field readOnly={readOnly} id="p7" name="p7" as="textarea" />

                    <label htmlFor="p8">4.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p8" name="p8" as="textarea" />

                    <label htmlFor="p9">5. Наименование уполномоченного органа на принятие решения о сокращении срока
                        действия договора найма специализированного жилого помещения, заключенного с лицами, которые
                        относились к категории детей-сирот и лиц из числа детей-сирот </label>
                    <Field readOnly={readOnly} id="p9" name="p9" as="textarea" />

                    <label htmlFor="p10">5.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p10" name="p10" as="textarea" />

                    <label htmlFor="p11">6. Наименование органа, уполномоченного на выдачу заключений об отсутствии у
                        лиц, подавших заявления о предоставлении выплаты, предусмотренной статьей 8.1. Федерального
                        закона № 159-ФЗ, обстоятельств, свидетельствующих о необходимости оказания таким лицам
                        содействия в преодолении трудной жизненной ситуации</label>
                    <Field readOnly={readOnly} id="p11" name="p11" as="textarea" />

                    <label htmlFor="p12">6.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p12" name="p12" as="textarea" />

                    <label htmlFor="p13">7. Наименование органа, уполномоченного на выдачу заключений об отсутствии у
                        лиц, подавших заявления о сокращении срока действия договора найма специализированного
                        жилого
                        помещения, обстоятельств, свидетельствующих о необходимости оказания таким лицам содействия
                        в
                        преодолении трудной жизненной ситуации</label>
                    <Field readOnly={readOnly} id="p13" name="p13" as="textarea" />

                    <label htmlFor="p14">7.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p14" name="p14" as="textarea" />

                    <label htmlFor="p15">8. Наименование уполномоченного органа для адресации межведомственного
                        запроса о
                        соответствии жилого помещения, приобретаемого получателем выплаты (в соответствии статьей
                        8.1.
                        Федерального закона № 159-ФЗ), установленным санитарным и техническим правилам и нормам,
                        иным
                        требованиям законодательства</label>
                    <Field readOnly={readOnly} id="p15" name="p15" as="textarea" />

                    <label htmlFor="p16">8.1 контактная информация уполномоченного органа </label>
                    <Field readOnly={readOnly} id="p16" name="p16" as="textarea" />

                    <label htmlFor="p17">9. Орган исполнительной власти субъекта Российской Федерации, а также
                        контактные
                        данные ответственного за заполнение формы запроса (ФИО, должность, номер телефона, адрес
                        электронной почты)</label>
                    <Field readOnly={readOnly} id="p17" name="p17" as="textarea" />

                    <label htmlFor="report">Прикрепить файл отчета</label>
                    <div style={{ marginBottom: '10px' }}>
                        {respData.report && (
                            <div style={{ marginBottom: '5px' }}>
                                Текущий файл: <a href={respData.report} target="_blank" rel="noopener noreferrer">
                                    Просмотреть прикрепленный файл
                                </a>
                            </div>
                        )}
                        <input
                            id="report"
                            name="report"
                            type="file"
                            onChange={(event) => {
                                setFile(event.currentTarget.files[0]);
                            }}
                            disabled={readOnly}
                        />
                        {file && (
                            <div style={{ marginTop: '5px', fontSize: '0.9em', color: '#666' }}>
                                Выбран новый файл: {file.name}
                            </div>
                        )}
                    </div>

                    <p></p>
                    <button type="submit">Отправить</button>
                    <p></p>
                    {respData.file && readOnly && <a href={respData.file}>Скачать файл</a>}
                    <p></p>
                    <div style={{ color: "red" }}>{err}</div>
                </Form>
            )}
        </Formik>
    );
};